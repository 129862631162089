import React from "react";
import { Button } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import {
  updateRepo,
  restartTask,
  stopTask,
  runTask,
  getWebSocket,
} from "../../../api/systemctl";
import { Settings } from "../../../Settings";

const wait = (deley) =>
  new Promise((resolve) => setTimeout(() => resolve(), deley));

const startUpdateRepo = async (setDisible, setProgress) => {
  const services = Settings.get("services", []).filter((service) =>
    Settings.has(`current_machines.value.services.${service.name}`, false)
  );
  const l = services.length;

  setDisible(true);

  for (let service of services) {
    if (service.name === "odin") continue;

    await updateRepo(service.name, service.value.project);
    const index = services.indexOf(service);

    setProgress(Math.round((index / l) * 100));
  }

  setProgress(0);
  setDisible(false);
};

const startRestartService = async (setDisible, setProgress) => {
  const services = Settings.get("services", []).filter((service) =>
    Settings.has(`current_machines.value.services.${service.name}`, false)
  );
  const l = services.length;

  setDisible(true);

  for (let service of services) {
    if (service.name === "odin") continue;

    const index = services.indexOf(service);
    const availableTasks = Settings.get(
      `current_machines.value.services.${service.name}`,
      []
    );

    for (let taskName in service.value.tasks) {
      const task = service.value.tasks[taskName];

      if (availableTasks.includes("*") || availableTasks.includes(taskName)) {
        if (task.systemctl) {
          for (let p of task.systemctl) {
            if (p.sysId) {
              await restartTask(p.sysId);
              await wait(100);
            }
          }
        }
      }
    }

    setProgress(Math.round((index / l) * 100));
  }

  setProgress(0);
  setDisible(false);
};

const startStopService = async (setDisible, setProgress) => {
  const services = Settings.get("services", []).filter((service) =>
    Settings.has(`current_machines.value.services.${service.name}`, false)
  );
  const l = services.length;

  setDisible(true);

  for (let service of services) {
    if (service.name === "odin") continue;

    const index = services.indexOf(service);
    const availableTasks = Settings.get(
      `current_machines.value.services.${service.name}`,
      []
    );

    for (let taskName in service.value.tasks) {
      const task = service.value.tasks[taskName];

      if (availableTasks.includes("*") || availableTasks.includes(taskName)) {
        if (task.systemctl) {
          for (let p of task.systemctl) {
            if (p.sysId) {
              await stopTask(p.sysId);
              await wait(100);
            }
          }
        }
      }
    }

    setProgress(Math.round((index / l) * 100));
  }

  setProgress(0);
  setDisible(false);
};

const startStartService = async (setDisible, setProgress) => {
  const services = Settings.get("services", []).filter((service) =>
    Settings.has(`current_machines.value.services.${service.name}`, false)
  );
  const l = services.length;

  setDisible(true);

  for (let service of services) {
    if (service.name === "odin") continue;

    const index = services.indexOf(service);
    const availableTasks = Settings.get(
      `current_machines.value.services.${service.name}`,
      []
    );

    for (let task in service.value.tasks) {
      if (service.name && task) {
        if (availableTasks.includes("*") || availableTasks.includes(task)) {
          await runTask(
            `${service.name}-${task}-${
              (Date.now() / 1000000000).toString().split(".")[1]
            }`
          );
          await wait(100);
        }
      }
    }

    setProgress(Math.round((index / l) * 100));
  }

  setProgress(0);
  setDisible(false);
};

const useUpdateRepoCounter = () => {
  const [progress, setProgres] = React.useState(0);
  const [disible, setDisible] = React.useState(false);

  return [
    progress,
    disible,
    () => {
      startUpdateRepo(setDisible, setProgres);
    },
  ];
};

const useServiceRestarter = () => {
  const [progress, setProgres] = React.useState(0);
  const [disible, setDisible] = React.useState(false);

  return [
    progress,
    disible,
    () => {
      startRestartService(setDisible, setProgres);
    },
  ];
};

const useServiceStoper = () => {
  const [progress, setProgres] = React.useState(0);
  const [disible, setDisible] = React.useState(false);

  return [
    progress,
    disible,
    () => {
      startStopService(setDisible, setProgres);
    },
  ];
};

const useServiceStarter = () => {
  const [progress, setProgres] = React.useState(0);
  const [disible, setDisible] = React.useState(false);

  return [
    progress,
    disible,
    () => {
      startStartService(setDisible, setProgres);
    },
  ];
};

export default ({ project, ...props }) => {
  const [updateProgress, updateDisable, startUpdate] = useUpdateRepoCounter();
  const [restartProgress, restartDisable, startRestarter] =
    useServiceRestarter();
  const [stopProgress, stopDisable, startStop] = useServiceStoper();
  const [startProgress, startDisable, startStart] = useServiceStarter();

  const updateServiceKV = async () => {
    const socket = getWebSocket();
    await socket.fetch(`http://${project.global_host}/utils/update/kv`, {});
  };

  return (
    <Grid item xs={12} style={{ marginTop: 10, marginBottom: 3 }}>
      {project.bitbucket && (
        <>
          <Button
            disabled={updateDisable}
            onClick={startUpdate}
            style={{ marginLeft: 10 }}
            variant="contained"
            size="small"
            color="inherit"
          >
            {updateProgress
              ? `Обновить все репо ${updateProgress} %`
              : "Обновить все репо"}
          </Button>
        </>
      )}
      <Button
        disabled={restartDisable}
        onClick={startRestarter}
        style={{ marginLeft: 10 }}
        variant="contained"
        size="small"
        color="inherit"
      >
        {restartProgress
          ? `Перезагрузить все сервисы ${restartProgress} %`
          : "Перезагрузить все сервисы"}
      </Button>
      <Button
        disabled={stopDisable}
        onClick={startStop}
        style={{ marginLeft: 10 }}
        variant="contained"
        size="small"
        color="inherit"
      >
        {stopProgress
          ? `Остановить все сервисы ${stopProgress} %`
          : "Остановить все сервисы"}
      </Button>
      <Button
        disabled={startDisable}
        onClick={startStart}
        style={{ marginLeft: 10 }}
        variant="contained"
        size="small"
        color="inherit"
      >
        {startProgress
          ? `Запустить все сервисы ${startProgress} %`
          : "Запустить все сервисы"}
      </Button>

      <Button
        onClick={updateServiceKV}
        style={{ marginLeft: 10 }}
        variant="contained"
        size="small"
        color="inherit"
      >
        Обновить kv всех сервисов
      </Button>
    </Grid>
  );
};
