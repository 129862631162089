import React from "react";
import { Grid } from "@material-ui/core";
import { Translate } from "../../../components/Translate";
import { Settings } from "../../../Settings";

export default props => {
  const projectKey = props.match.params.key;
  const { project, socket } = props;
  const [translate, setTranslate] = Settings.useStore("translate", {
    [projectKey]: {}
  });

  const global_host = Settings.get("global_host");

  return (
    <Grid item xs={12}>
      <Translate
        project={project}
        translate={translate}
        handlerSetTranslate={setTranslate}
        socket={socket}
        global_host={global_host}
      />
    </Grid>
  );
};
