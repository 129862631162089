import React from "react";
import { Button, Link } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { updateRepo, restartTask, stopTask, runTask } from "../../../api/systemctl";
import { Settings } from "../../../Settings";

const wait = deley => new Promise(resolve => setTimeout(() => resolve(), deley));


export default (props) => {


    return (
        <Grid item xs={12} style={{ marginTop: 10, marginBottom: 3, display: 'flex', 'justifyContent': 'flex-end' }}>
            <Button
                onClick={() => props.setLiveMode(!props.isLiveMode)}
                style={{ marginRight: 10 }}
                variant="contained"
                size="small"
                color={props.isLiveMode ? "primary" : "default"}
            >
                Live Log
            </Button>
        </Grid>
    )
}