import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import SaveIcon from "@material-ui/icons/Save";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    padding: 20
  },
  button: {
    marginLeft: theme.spacing(2)
  },
  checkout: {
    marginLeft: theme.spacing(2)
  },
  paper: {
    marginTop: 10
  },
  minTab: {
    fontSize: 12
  },
  root2: {
    width: "100%"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  oldValueSection: {
    marginTop: 5,
    flexGrow: 1,
    minHeight: "70vh"
  },
  newValueSection: {
    marginTop: 5,
    flexGrow: 1,
    minHeight: "70vh"
  }
}));

export default props => {
  const classes = useStyles();
  const { project } = props;

  const [id, setId] = useState();
  const [offset, setOffset] = useState();
  const [error, setError] = useState("");
  const [rewrite, setRewrite] = useState(false);

  useEffect(() => {
    window.oldVal = window.ace.edit(
      document.querySelector("#old-entity-value")
    );

    window.newVal = window.ace.edit(
      document.querySelector("#new-entity-value")
    );
    return () => {
      window.oldVal = undefined;
      window.newVal = undefined;
    };
  });

  const updateOldValue = data => {
    window.oldVal.getSession().setMode("ace/mode/javascript");
    window.oldVal.getSession().setUseSoftTabs(true);
    window.oldVal.getSession().setUseWrapMode(true);
    window.oldVal.getSession().setOption("useWorker", false);
    window.oldVal.setOption("fontSize", "14px");
    window.oldVal.$blockScrolling = Infinity;
    window.oldVal.setValue(data);
  };

  const updateNewValue = data => {
    window.newVal.getSession().setMode("ace/mode/javascript");
    window.newVal.getSession().setUseSoftTabs(true);
    window.newVal.getSession().setUseWrapMode(true);
    window.newVal.getSession().setOption("useWorker", false);
    window.newVal.setOption("fontSize", "14px");
    window.newVal.$blockScrolling = Infinity;
    window.newVal.setValue(data);
  };

  const onLoad = async e => {
    e.preventDefault();
    console.log("wadajwehfbakjwheb");

    setError(); //clear error

    try {
      const { json: data } = await props.socket.fetch(
        `http://${project.global_host}/search/compensate`,
        { _id: id },
        "POST"
      );

      if (data.exception) {
        throw new Error(data.exception);
      }

      if (data._offset === undefined || data._id === undefined) {
        throw new Error("Not found");
      }

      setId(data._id);
      setOffset(data._offset);

      const str = JSON.stringify(data, null, "\t");

      const newData = {
        ...data,
        _offset: undefined,
        _partition: undefined,
        _service: undefined,
        _parent: undefined
      };
      const newStr = JSON.stringify(newData, null, "\t");

      updateOldValue(str);
      updateNewValue(newStr);
    } catch (e) {
      console.log("ERROR ", e);
      setError(e.message);
    }
  };

  const onSave = async e => {
    e.preventDefault();
    setError();
    try {
      const json = window.newVal.getValue();

      if (!id) {
        throw new Error("Id is required");
      }

      const newState = JSON.parse(json);

      const { json: data } = await props.socket.fetch(
        `http://${project.global_host}/utils/compensate`,
        {
          id: id,
          offset,
          rewrite,
          state: { ...newState, _id: undefined }
        },
        "PUT"
      );

      console.log("RESULT SAVE", data);
    } catch (e) {
      console.log("ERROR", e);
      setError(e.message);
    }
  };

  const onChange = async e => {
    e.preventDefault();
    console.log("awldwkjdnakwd");
    setId(e.target.value);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            id="outlined-password-input"
            label="ID"
            size="small"
            type="_id"
            value={id}
            variant="outlined"
            onChange={onChange}
          />
          <Button
            variant="contained"
            color="default"
            className={classes.button}
            startIcon={<CloudUploadIcon />}
            onClick={onLoad}
            disabled={!id}
          >
            Load
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            startIcon={<SaveIcon />}
            onClick={onSave}
            disabled={!id}
          >
            Save
          </Button>
          <FormControlLabel
            control={
              <Checkbox
                className={classes.checkout}
                checked={rewrite}
                onChange={() => setRewrite(!rewrite)}
                value="rewrite"
              />
            }
            label="Rewrite"
          />
          {error ? (
            <Alert variant="filled" severity="error">
              {error}
            </Alert>
          ) : null}
        </Grid>
        <Grid item xs={6}>
          <div className={classes.oldValueSection} id="old-entity-value">
            {" "}
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.newValueSection} id="new-entity-value">
            {" "}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
