import React, { useEffect } from "react";

import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1
  },
  logSection: {
    marginTop: 5,
    flexGrow: 1,
    minHeight: "62vh"
  },
  paper: {
    marginTop: 10
  }
}));

export default function LogContainer(props) {
  const classes = useStyles();
  const { process, socket, currentMachine, service, partition } = props;

  useEffect(() => {
    window.aceLog = window.ace.edit(document.querySelector("#log-section"));
    window.aceLog.getSession().setMode("ace/mode/javascript");
    window.aceLog.getSession().setUseSoftTabs(true);
    window.aceLog.getSession().setUseWrapMode(true);
    window.aceLog.getSession().setOption("useWorker", false);
    window.aceLog.setOption("fontSize", "11px");
    window.aceLog.$blockScrolling = Infinity;

    socket.on("log", log => {
      const session = window.aceLog.getSession();
      const length = (window.aceLog.getValue() || "").length;
      const text = log.join("\r");

      let sep = "";

      if (length > 0 && !/\n$/.test(text)) sep = "\n";

      session.insert(
        {
          row: session.getLength(),
          column: 0
        },
        text + sep
      );

      window.aceLog.gotoLine(Infinity);
    });

    return () => {
      socket.off("log");
      window.aceLog.setValue("");
    };
  }, [process, currentMachine, socket, service, partition]);

  if (!process) return null;

  return (
    <Paper>
      <div className={classes.logSection} id="log-section"></div>
    </Paper>
  );
}
