import React from "react";
import "./App.css";
// import Navigator from "./navigator/index";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Main from "./pages/Main";
import { Project } from "./pages/Project";
// import Translate from "./pages/Project/Translate";
// import AppView from "./../pages/AppView";
// import Settings from "./Settings";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Switch>
          <Route exact path="/" component={Main} />
          <Route path="/project/:key" component={Project} />
          {/* <Route path="/project/:key/translate" component={Translate} /> */}

          {/* <Route exact path="/project/:key/translate" component={Translate} /> */}
          {/* <Navigator /> */}
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
