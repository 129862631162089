const { Translate } = require("@google-cloud/translate").v2;

// Instantiates a client
const translate = new Translate({
  projectId: process.env.REACT_APP_GOOGLE_TRANSLATE_PROJECT_ID,
  key: process.env.REACT_APP_GOOGLE_TRANSLATE_KEY
});

export const listLanguagesSample = async () => {
  // Lists available translation language with their names in English (the default).
  const [languages] = await translate.getLanguages();

  return languages;
};

export default async (data, target) => {
  const keys = Object.keys(data);

  let size = 100; //размер подмассива
  let subKeysArray = []; //массив в который будет выведен результат.
  for (let i = 0; i < Math.ceil(keys.length / size); i++) {
    subKeysArray[i] = keys.slice(i * size, i * size + size);
  }

  const result = [];

  for (const keysArr of subKeysArray) {
    const translateArr = keysArr.map(i => data[i]);

    let [translations] = await translate.translate(translateArr, {
      to: target,
      model: "nmt"
    });

    translations = Array.isArray(translations) ? translations : [translations];

    if (!translations.length) continue;

    translations.forEach((translatedText, i) =>
      result.push({
        key: keysArr[i],
        text: data[keysArr[i]],
        translation: translatedText
      })
    );
  }

  return result;
};

// export { detectPromise, getLangsPropmise };
