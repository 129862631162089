import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import { Typography, Link } from "@material-ui/core";
import { Link as NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  toolbar: {
    ...theme.mixins.toolbar,
    justifyContent: "space-between"
  },
  appbar: {
    height: "60px",
    marginBottom: 2
  },
  link: {
    margin: theme.spacing(1),
    color: "#fff",
    fontSize: 18
  },
  menu: {
    margin: theme.spacing(4)
  },
  submenu: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end"
  },
  title: {
    color: "#fff"
  },
  back: {
    color: "#fff",
    marginRight: theme.spacing(4)
  }
}));

export default props => {
  const classes = useStyles();

  const { project } = props;
  return (
    <AppBar className={classes.appbar} position="static">
      <Toolbar className={classes.toolbar} variant="dense">
        <NavLink to="/">
          <Typography variant="h5" className={classes.back}>
            {`<<Back`}
          </Typography>
        </NavLink>
        <NavLink to={`/project/${project.key}`}>
          <Typography variant="h5" className={classes.title}>
            {project.title.toUpperCase()}
          </Typography>
        </NavLink>
        <Typography className={classes.menu}>
          {project.services.map(s => {
            return (
              <Link
                target={"_blank"}
                key={s.title}
                href={s.link}
                className={classes.link}
              >
                {s.title}
              </Link>
            );
          })}
          {/* <NavLink
            to={`/project/${project.key}/translate`}
            className={classes.link}
          >
            Translate
          </NavLink> */}
        </Typography>
        <Typography className={classes.submenu}>
          {(project.links || []).map(link => (
            <Link
              target={"_blank"}
              key={link.title}
              href={link.link}
              className={classes.link}
            >
              {link.title}
            </Link>
          ))}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};
