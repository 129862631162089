import React from "react";
import { Typography, Grid, Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Settings } from "./../../Settings";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    width: "100%",
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center"
  },
  card: {
    width: 200,
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    paddingTop: 20,
    paddingBottom: 20,
    marginRight: 10
  },
  linkContainer: {
    flex: 1
  },
  control: {}
}));

function Main() {
  const classes = useStyles();
  const projects = Settings.get("projects");

  return (
    <Grid container className={classes.root}>
      {Object.keys(projects).map(key => {
        const p = projects[key];
        return (
          <Grid key={p.key} item>
            <Link to={`/project/${p.key}`} params={{ k: p.key }}>
              <Card className={classes.card}>
                <Typography
                  variant="h5"
                  style={{ textAlign: "center", color: "#000" }}
                >
                  {p.title}
                </Typography>
              </Card>
            </Link>
          </Grid>
        );
      })}
    </Grid>
  );
}

Main.path = "";

export default Main;
