import React, { useState, useEffect } from "react";
// import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
// import { Paper } from "@material-ui/core";
import { Tabs, AppBar, Typography, Button } from "@material-ui/core";
import Tab from "@material-ui/core/Tab";
import Grid from "@material-ui/core/Grid";
// import Typography from "@material-ui/core/Typography";
import Process from "./Process";
import Policy from "./Policy";
import Compensate from "./Compensate";

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: 10,
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  paper: {
    marginBottom: 5
  }
}));

export default function Service(props) {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const { currentService, project, socket, projectKey } = props;

  useEffect(() => {
    setValue(value);
  }, [props.currentMachine]);

  return (
    // <div className={classes.root}>
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Tabs value={value} onChange={(e, v) => setValue(v)}>
          <Tab label={"Процессы"} />
          <Tab label={"Политики"} />
          <Tab label={"Compensate"} />
        </Tabs>
      </Grid>

      <Grid item xs={12}>
        {value === 0 && (
          <Process
            project={project}
            socket={socket}
            currentService={currentService}
            currentMachine={props.currentMachine}
          />
        )}
        {value === 1 && (
          <Policy
            socket={socket}
            currentService={currentService}
            currentMachine={props.currentMachine}
          />
        )}

        {value === 2 && (
          <Compensate
            project={project}
            currentMachine={props.currentMachine}
            socket={socket}
            currentService={currentService}
            projectKey={projectKey}
          />
        )}
      </Grid>
    </Grid>
    // </div>
  );
}
