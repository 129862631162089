import { Store } from "./store/store";
import settings from "./constants/projects.json";
// import { getAppTourScreens } from './../utils/apptour';

const defaultShchema = settings.reduce(
  (acc, e) => ({ ...acc, [e.key]: e }),
  {}
);

export const Settings = new Store({
  key: "store:settings",
  defaultShchema: {
    projects: defaultShchema,
    logLevel:2
  }
});

window.Settings = Settings;
