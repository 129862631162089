const delimetr = ':';

export default class {
  constructor() {
    this._listeners = {};
  }

  static delimetr = delimetr;

  emit(event, message = this) {
    if (this._listeners[event] === undefined) return this;

    let l = this._listeners[event].length;

    while (l--) {
      this._listeners[event][l].callback(message, event);
    }

    return this;
  }

  once(event, callback) {
    const self = this;

    this.on(event, function F(message) {
      callback(message);

      self.off(event, F);
    });
  }

  on(_event, callback) {
    if (Array.isArray(_event)) return _event.forEach(e => this.on(e, callback));

    const [event, postfix] = _event.split(delimetr);

    if (this._listeners[event] === undefined) this._listeners[event] = [];

    this._listeners[event].push({ _event, event, postfix, callback });

    return () => this.off(_event);
  }

  off(_event) {
    if (Array.isArray(_event)) return _event.forEach(e => this.off(e));

    const [event, postfix] = _event.split(delimetr);

    if (event === '*') {
      for (let e in this._listeners) {
        this.off(`${e}${delimetr}${postfix}`);
      }

      return;
    }

    if (this._listeners[event] === undefined) this._listeners[event] = [];

    this._listeners[event].forEach((em, index) => {
      if (em._event === _event) {
        this._listeners[event].splice(index, 1);
      }
    });

    return this;
  }
}
