export const formatData = (rows, columns) => {
  const result = columns.reduce((acc, e) => {
    if (e.field !== "key") {
      acc[e.field] = { dictionary: {} };
    }

    return acc;
  }, {});

  const langsArr = parseLangs(columns);

  return rows.reduce((acc, r) => {
    const { key } = r;
    const langsKeys = Object.keys(r).filter(
      k => k !== "id" && k !== "key" && k !== "isObject" && langsArr.includes(k)
    );

    langsKeys.forEach(lk => {
      try {
        acc[lk].dictionary[key] = // тут сложность в том что нету ключа в колонке
          r.isObject.includes(lk) && r[lk] !== undefined
            ? JSON.parse(r[lk])
            : r[lk];
      } catch (err) {
        console.log(err);
        console.log(`KEY: ${key}  LANG: ${lk} VALUE: ${r[lk]}`);

        acc[lk].dictionary[key] = r[lk];
      }
    });

    return acc;
  }, result);
};

export const parseData = data => {
  if (data.root !== undefined && data.en === undefined) {
    data.en = data.root;
    delete data.root;
  }

  const langs = Object.keys(data);

  const columns = [
    { field: "key", title: "Key" },
    ...langs.sort(l => (l === "en" ? -1 : 1)).map(l => ({ field: l, title: l }))
  ];

  const keys = Object.keys(
    langs.reduce(
      (acc, l) =>
        data[l].dictionary && Object.keys(data[l].dictionary).length
          ? { ...acc, ...data[l].dictionary }
          : acc,
      {}
    )
  );

  const rows = keys.map((k, i) => {
    return langs.reduce(
      (accR, lang) => {
        return data[lang].dictionary[k]
          ? {
              ...accR,
              [lang]:
                typeof data[lang].dictionary[k] === "object"
                  ? JSON.stringify(data[lang].dictionary[k])
                  : data[lang].dictionary[k],
              isObject:
                typeof data[lang].dictionary[k] === "object"
                  ? [...accR.isObject, lang]
                  : accR.isObject
            }
          : accR;
      },
      { id: k, key: k, isObject: [] }
    );
  });

  return { columns, rows };
};

export const parseLangs = (columns = []) =>
  columns.filter(l => l.field !== "key").map(l => l.field);

export const readFile = file =>
  new Promise((res, rej) => {
    const reader = new FileReader();

    reader.onload = function() {
      console.log("ONLOAD");
      // pre-process data
      var binary = "";
      var bytes = new Uint8Array(reader.result);
      var length = bytes.byteLength;
      for (var i = 0; i < length; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      res(binary);
    };
    reader.onerror = function() {
      rej(reader.abort());
    };

    reader.readAsArrayBuffer(file);
  });
