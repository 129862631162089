import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Badge, Grid } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import ProcessContainer from "./ProcessContainer";
import LogContainer from "./LogContainer";
import {Settings} from './../../../Settings';
import { getWebSocket, updateRepo } from "./../../../api/systemctl";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  minTab: {
    fontSize: 12
  },
  minminTab: {
    fontSize: 10,
  },
  button: {
    marginRight: 20
  },
  service: {
    display: "flex",
    flexDirection: "row"
  },
  list: {
    height: "79vh",
    "overflow-y": "scroll"
  }
}));

export default function Process(props) {
  const { currentService, currentMachine, project } = props;
  let tasks = Object.keys(currentService.value.tasks);
  const classes = useStyles();
  const [value, setValue] = useState(null);
  const [waiting, setWaiting] = useState(false);
  const v = currentService.value.tasks[value] ? value : tasks[0];
  const [currentPart, setCurrentPart] = useState(0);

  console.log({props})

  tasks = tasks.filter(task => {
    if (currentMachine.value.services[currentService.name]){
      if(currentMachine.value.services[currentService.name].includes('*')){
        return true
      } else 
        return currentMachine.value.services[currentService.name].includes(task)
    } else 
      return false
  });


  const partList = currentService.value.tasks && currentService.value.tasks[v] && currentService.value.tasks[v].systemctl && currentService.value.tasks[v].systemctl[0] ? currentService.value.tasks[v].systemctl : [];
  const partitiion = currentService.value.tasks && currentService.value.tasks[v] && currentService.value.tasks[v].systemctl && currentService.value.tasks[v].systemctl[currentPart] ? currentService.value.tasks[v].systemctl[currentPart].partition : '0';

  const testRepo = service => {
    setWaiting(true);

    updateRepo(service.name, service.value.project).then(res => {
      alert(res);
      setWaiting(false);
    });
  };

  const changeTask = (e, v) => {
    setValue(v);
    setCurrentPart(0);
  }

  useEffect(() => {
    if (currentService.value.tasks[v] && currentService.value.tasks[v].systemctl)
      if (currentPart !== currentService.value.tasks[v].systemctl.length - 1) {
        setCurrentPart(0);
      }

  }, [partList.length])


  const updateServiceKV = async () => {
    await props.socket.fetch(`http://${props.project.global_host}/utils/update/kv`, {
      service: props.currentService.name
    });
  }

  return (
    <div className={classes.service}>
      <Grid style={{ marginTop: 10 }} container spacing={0}>
        <Grid item xs={12}>
          <div
            style={{ display: "flex", flexDirection: "row", marginLeft: 20 }}
          >
            <Typography variant="h6" noWrap>
              {currentService.name.toUpperCase()}
            </Typography>
            {project.bitbucket && (
              <Button
                disabled={waiting}
                onClick={() => !waiting && testRepo(currentService)}
                style={{ marginLeft: 10 }}
                variant="contained"
                size="small"
                color="inherit"
                className={classes.button}
              >
                {waiting ? "Обновление..." : "Обновить репо"}
              </Button>
            )}
            <Button
                disabled={waiting}
                onClick={updateServiceKV}
                style={{ marginLeft: 10 }}
                variant="contained"
                size="small"
                color="inherit"
                className={classes.button}
              >
                 Обновить kv сервиса
              </Button>
          </div>
        </Grid>

        <Grid item xs={12}>
          <ProcessContainer
            partition={partitiion}
            socket={props.socket}
            task={v}
            service={currentService.name}
            process={currentService.value.tasks[v]}
            currentMachine={currentMachine}
          />
        </Grid>

        <Grid item xs={12}>
          <Tabs value={v} onChange={changeTask}>
            {tasks.map(task => {
              const status = currentService.value.tasks[task].systemctl && currentService.value.tasks[task].systemctl[0]
                ? currentService.value.tasks[task].systemctl[0].status[1]
                : false;

              const count =    currentService.value.tasks[task].systemctl && currentService.value.tasks[task].systemctl[0] ? currentService.value.tasks[task].systemctl.length : 0;

              const color = status
                ? status === "active"
                  ? "primary"
                  : "error"
                : "secondary";
                

                let totalMemoty = 0;

                const sm = Settings.get(`load.${currentService.name}.${task}`, false);
        
                if (sm){
                    for (let part in sm){
                      totalMemoty += + sm[part].memory;
                    }
                  
                }



              return (
                <Tab
                  className={classes.minTab}
                  value={task}
                  key={task}
                  label={
                    <Badge style={{}} color={color} variant="dot">
                      {task} ({count}) | {totalMemoty.toFixed(2)} Mb
                    </Badge>
                  }
                />
              );
            })}
          </Tabs>
        </Grid>
        {partList.length > 0 && (
        <Grid item xs={12}>
          <Tabs classes={{    height:30}} value={currentPart} onChange={(e, v) => setCurrentPart(v)}>
            {partList.map((task, index) => {

              const status = task.status[1];


              const color = status
                ? status === "active"
                  ? "primary"
                  : "error"
                : "secondary";

              const totalMemory = Settings.get(`load.${currentService.name}.${v}.${task.partition}.memory`, 0);
              const upTime = Settings.get(`load.${currentService.name}.${v}.${task.partition}.uptime`, 0);

              return (
                <Tab
                  className={classes.minminTab}
                  value={index}
                  key={index}
                  label={
                    <Badge style={{}} color={color}>
                      {index} ({task.partition}) | {~~(totalMemory)} | ({(upTime/60).toFixed(2)})
                    </Badge>
                  }
                />
              );
            })}
          </Tabs>
        </Grid>
        )}
        <Grid item xs={12}>
          <LogContainer
            partition={partitiion}
            socket={props.socket}
            task={v}
            service={currentService.name}
            process={currentService.value.tasks[v]}
            currentMachine={currentMachine}
          />
        </Grid>
      </Grid>
    </div>
  );
}
