import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import MaterialTable from "material-table";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fade from "@material-ui/core/Fade";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { Alert, AlertTitle } from "@material-ui/lab";
import Chip from "@material-ui/core/Chip";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import XLSX from "xlsx";

import "./components/download";
import {
  default as googleTranslate,
  listLanguagesSample
} from "./components/google";

import {
  formatData,
  parseData,
  parseLangs,
  readFile
} from "./components/utils";
import { saveAs } from "file-saver";

const useStyles = makeStyles(theme => ({
  chip: {
    marginRight: theme.spacing(1)
  },
  select: {
    marginRight: theme.spacing(1)
  }
}));

const Table = props => {
  const classes = useStyles();
  const {
    project,
    translate,
    handlerSetTranslate: onSetTranslate,
    socket,
    global_host
  } = props;

  const {
    translate: { cdn, storage }
  } = project;
  const { key: projectKey } = project;

  const [loading, setLoading] = React.useState(false);
  const langKeys = parseLangs(translate[projectKey].columns);
  const [langs, setLangs] = React.useState(langKeys);
  const [error, setError] = React.useState();
  const [langVal, setLangVal] = React.useState();
  const [showAddLangButton, setShowAddLangButton] = React.useState(false);

  const onLoadFile = async e => {
    const file = e.target.files[0];

    const workbook = XLSX.read(await readFile(file), {
      type: "binary",
      cellDates: true,
      cellStyles: true
    });

    const sheet = workbook.Sheets[Object.keys(workbook.Sheets)[0]];
    const parse = XLSX.utils.sheet_to_json(sheet);

    const json = parse.reduce((acc, row) => {
      const { key } = row;
      const isObject =
        row.isObject !== undefined ? JSON.parse(row.isObject) : [];
      const langs = Object.keys(row).filter(
        k => k !== "isObject" && k !== "key"
      );
      console.log(langs);
      langs.forEach(l => {
        if (acc[l] === undefined) acc[l] = { dictionary: {} };

        acc[l].dictionary[key] = isObject.includes(l)
          ? JSON.parse(row[l])
          : row[l];
      });
      return acc;
    }, {});

    const { columns, rows } = parseData(json);
    const oldData = translate[projectKey].oldData || {};

    const updateColumns = columns.map(e =>
      oldData[e.field] !== undefined && oldData[e.field]._id !== undefined
        ? {
            ...e,
            title: `${e.title} (${oldData[e.field]._id})`,
            _id: oldData[e.field]._id
          }
        : e
    );

    onSetTranslate({
      ...translate,
      [projectKey]: { rows: rows, columns: updateColumns, oldData }
    });
  };

  const onDownloadJson = async e => {
    e.preventDefault();

    const result = formatData(
      translate[projectKey].rows,
      translate[projectKey].columns
    );

    saveAs(
      new Blob([JSON.stringify(result)], { type: "application/json" }),
      `${projectKey}.json`
    );
  };

  const onDownloadExcel = async e => {
    e.preventDefault();
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const s2ab = s => {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    };

    const wb = XLSX.utils.book_new();
    wb.Props = {
      Title: "Translations ",
      Subject: projectKey,
      Author: projectKey,
      CreatedDate: new Date()
    };

    wb.SheetNames.push(projectKey);

    const ws_data = translate[projectKey].rows.map(e => ({
      ...Object.keys(e).reduce((acc, key) => {
        if (key === "id" || key === "tableData") return acc;
        if (key === "isObject")
          return e.isObject.length
            ? { ...acc, isObject: JSON.stringify(e[key]) }
            : acc;

        return { ...acc, [key]: e[key] };
      }, {})
    }));

    const ws = XLSX.utils.json_to_sheet(ws_data);
    wb.Sheets[projectKey] = ws;

    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

    saveAs(
      new Blob([s2ab(wbout)], { type: fileType }),
      `${projectKey}${fileExtension}`
    );
  };

  const onTranslate = async e => {
    e.preventDefault();
    setLoading(true);

    const langKeys = parseLangs(translate[projectKey].columns);

    const newRows = [...translate[projectKey].rows];

    for (const lang of langKeys) {
      try {
        const translateLangs = langKeys.filter(e => e !== lang);

        for (const l of translateLangs) {
          const data = newRows.reduce((acc, obj, i) => {
            if (
              (obj[l] === undefined || obj[l] === "") &&
              obj[lang] !== undefined &&
              obj[lang] !== "" &&
              !obj.isObject.includes(lang)
            ) {
              acc[i] = obj[lang];
            }

            return acc;
          }, {});

          if (!Object.keys(data).length) continue;

          const translatingData = await googleTranslate(data, l);

          if (!translatingData.length) continue;

          translatingData.forEach(obj => {
            newRows[obj.key] = { ...newRows[obj.key], [l]: obj.translation };
          });
        }
        onSetTranslate({
          ...translate,
          [projectKey]: { ...translate[projectKey], rows: newRows }
        });
      } catch (e) {
        console.log(e);
      }
    }

    setLoading(false);
  };

  const createFile = async (data, name, lang, prefix = "LANG") => {
    const {
      json: { upload_url, _id, content_type }
    } = await socket.fetch(`http://${global_host}/files`, {
      content_type: "application/json",
      prefix: "LANG",
      key: lang,
      name
    });

    const res = await fetch(upload_url, {
      method: "PUT",
      mode: "cors",
      body: JSON.stringify(data),
      headers: {
        Accept: content_type,
        "Content-Type": "application/json"
      }
    });

    return res;
  };

  const removeFile = async id => {
    const res = await socket.fetch(
      `http://${global_host}/files`,
      { _id: id },
      "DELETE"
    );
    return res;
  };

  const onUpdate = async e => {
    e.preventDefault();

    //обновляем файлы по колонкам, если нету файл то создать, если нету в колонке то удалить
    console.log(translate);
    try {
      const result = formatData(
        translate[projectKey].rows,
        translate[projectKey].columns
      );
      console.log(result);

      const langs = parseLangs(translate[projectKey].columns);

      console.log(langs);

      console.log(translate[projectKey].oldData);

      const remoteLang = Object.keys(translate[projectKey].oldData).filter(
        l => !langs.includes(l)
      );

      console.log("remoteLang", remoteLang);

      if (langs.length) {
        for (const lang of langs) {
          if (
            // если есть старый файл
            translate[projectKey].oldData !== undefined &&
            translate[projectKey].oldData[lang] !== undefined
          ) {
            if (
              // если этот файл обновили
              JSON.stringify(translate[projectKey].oldData[lang].file) !==
              JSON.stringify({ [lang]: result[lang] })
            ) {
              console.log("suad, zashelll");
              const _id = translate[projectKey].oldData[lang]._id;

              const res = await createFile(
                { [lang]: result[lang] },
                translate[projectKey].oldData[lang].name || lang,
                lang
              );

              if (res.status === 200) {
                const res = await removeFile(_id);
                console.log("REMOVE FILE", res);
                console.log(`DELETE FILE LANG ${_id}`);
              }
              console.log(`Update LANG ${lang}`);
            }
          } else {
            // ЕСЛИ НЕТ ФАЙЛОВ СОЗДАЕМ НОВЫЕ!!!
            await createFile({ [lang]: result[lang] }, lang, lang);
          }
        }
      }

      // // удаляем переводы которые не нужны
      for (const lang of remoteLang) {
        const _id = translate[projectKey].oldData[lang]._id;
        const res = await removeFile(_id);
        console.log("`removed FIEL", _id);
      }
    } catch (e) {
      console.log(e);
      setError(e.message);
    }
  };

  const getTranslateList = async () => {
    const { json } = await socket.fetch(`http://${global_host}/search/search`, {
      service: "file",
      size: 100,
      sorting: [
        {
          field: "created_at",
          order: -1
        }
      ],
      filter: [
        {
          field: "prefix",
          operation: "eq",
          value: "LANG"
        },
        {
          field: "status",
          field_type: "bitmask",
          mode: "allSet",
          value: 32
        },
        {
          field: "status",
          field_type: "bitmask",
          mode: "allUnset",
          value: 18
        }
      ],
      resolve: {
        mode: 2,
        fields: ["_id", "url", "name", "key"]
      }
    });
    return json;
  };

  const onRowUpdate = async (newData, oldData) => {
    if (!newData.key) {
      alert("Key is required");
    }

    const data = [...translate[projectKey].rows];
    const index = data.findIndex(e => e.id === oldData.id);

    if (newData.key !== oldData.key && data.find(e => e.id === newData.key)) {
      alert("This is key already exists.");
    } else {
      newData.id = newData.key;
      data[index] = newData;

      onSetTranslate({
        ...translate,
        [projectKey]: { ...translate[projectKey], rows: data }
      });
    }
  };

  const onRowDelete = async oldData => {
    const data = [...translate[projectKey].rows].filter(
      e => e.id !== oldData.id
    );

    onSetTranslate({
      ...translate,
      [projectKey]: { ...translate[projectKey], rows: data }
    });
  };

  const onRowAdd = async newData => {
    if (!newData.key) {
      alert("Key is required");
    }

    if (translate[projectKey].rows.find(e => e.id === newData.key)) {
      alert("This is key already exists.");
    } else {
      newData.id = newData.key;
      const data = [...translate[projectKey].rows];
      data.push(newData);

      onSetTranslate({
        ...translate,
        [projectKey]: { ...translate[projectKey], rows: data }
      });
    }
  };

  React.useEffect(() => {
    (async () => {
      if (socket === null) return;

      if (!translate[projectKey].rows) {
        setLoading(true);
        try {
          const data = await getTranslateList();
          let loadData = {};
          const oldData = {};

          if (data.total) {
            for (const d of data.data) {
              const response = await fetch(`${cdn}${d.url}`, { method: "GET" });
              const jsonObj = await response.json();

              const langKey = Object.keys(jsonObj)[0];
              oldData[langKey] = {
                ...d,
                file: jsonObj
              };
              loadData = { ...loadData, ...jsonObj };
            }

            const { columns, rows } = parseData(loadData);

            const updateColumns = columns.map(e =>
              oldData[e.field] !== undefined &&
              oldData[e.field]._id !== undefined
                ? {
                    ...e,
                    title: `${e.title} (${oldData[e.field]._id})`,
                    _id: oldData[e.field]._id
                  }
                : e
            );

            onSetTranslate({
              ...translate,
              [projectKey]: { rows: rows, columns: updateColumns, oldData }
            });
          }
        } catch (e) {
          console.log("Error download i18n files");
          console.error(e);
          setError(e);
        }

        setLoading(false);

        return () => {
          onSetTranslate({
            ...translate,
            [projectKey]: { rows: [], columns: [] }
          });
        };
      }
    })();
  }, [socket]);

  const onDeleteLanguage = langVal => {
    console.log("onDeleteLanguage");

    try {
      const langKeys = parseLangs(translate[projectKey].columns);
      if (!langKeys.includes(langVal)) {
        throw new Error(`Key ${langVal} not exists.`);
      }
      console.log(translate[projectKey].columns);
      onSetTranslate({
        ...translate,
        [projectKey]: {
          ...translate[projectKey],
          columns: [
            ...translate[projectKey].columns.filter(c => c.field !== langVal)
          ]
        }
      });
    } catch (e) {
      console.log(e);
      setError(e);
    }

    setShowAddLangButton(false);
    setLangVal(undefined);
  };

  const onChangeLanguage = event => {
    console.log("onChangeLanguage");
    console.log(event.target.value);

    setLangVal(event.target.value);
    if (event.target.value) {
      setShowAddLangButton(true);
    }
  };

  const onAddLanguage = event => {
    if (langVal) {
      console.log(langVal);
      try {
        const langKeys = parseLangs(translate[projectKey].columns);
        if (langKeys.includes(langVal)) {
          throw new Error(`Key ${langVal} already exists.`);
        }
        console.log(translate[projectKey].columns);
        onSetTranslate({
          ...translate,
          [projectKey]: {
            ...translate[projectKey],
            columns: [
              ...translate[projectKey].columns,
              { field: langVal, title: langVal }
            ]
          }
        });
      } catch (e) {
        console.log(e);
        setError(e);
      }
    }

    setShowAddLangButton(false);
    setLangVal(undefined);
  };

  React.useEffect(() => {
    (async () => {
      const langs = await listLanguagesSample();
      setLangs(langs);
    })();
    return () => null;
  }, []);

  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        style={{ marginBottom: 20, marginTop: 20 }}
      >
        {error ? (
          <Grid item xs={12}>
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {error.message}
            </Alert>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <Grid container justify="flex-start">
            <Grid item xs={10}>
              <FormControl>
                <Input
                  id="my-input"
                  aria-describedby="my-helper-text"
                  type="file"
                  accept=".json"
                  onChange={onLoadFile}
                />
                <FormHelperText id="my-helper-text">
                  Upload your json file.
                </FormHelperText>
              </FormControl>
              <FormControl>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onTranslate}
                >
                  Tranlate
                </Button>
              </FormControl>

              <FormControl>
                <Fade
                  in={loading}
                  style={{
                    transitionDelay: loading ? "800ms" : "0ms"
                  }}
                  unmountOnExit={true}
                >
                  <CircularProgress />
                </Fade>
              </FormControl>
            </Grid>

            <Grid item xs={2}>
              <FormControl>
                <Button variant="contained" color="primary" onClick={onUpdate}>
                  Update
                </Button>
              </FormControl>
              <FormControl>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onDownloadExcel}
                >
                  Excel
                </Button>
              </FormControl>
              <FormControl>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onDownloadJson}
                >
                  JSON
                </Button>
              </FormControl>
            </Grid>
            <Grid xs={12}>
              <Typography variant={"p"}>LANGS:</Typography>

              {parseLangs(translate[projectKey].columns).map(lang => (
                <Chip
                  className={classes.chip}
                  variant="outlined"
                  size="small"
                  label={lang}
                  icon="none"
                  // onClick={handleClick}
                  onDelete={() => onDeleteLanguage(lang)}
                  // deleteIcon={<DoneIcon />}
                />
              ))}
              <Select
                className={classes.select}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={langVal}
                onChange={onChangeLanguage}
              >
                {langs.map(({ code, name }) => (
                  <MenuItem disable={true} value={code}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
              {showAddLangButton ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onAddLanguage}
                >
                  Add Lang
                </Button>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {!translate[projectKey] ? null : (
            <MaterialTable
              title={project.title}
              columns={translate[projectKey].columns}
              data={translate[projectKey].rows}
              options={{
                addRowPosition: "first",
                paging: true,
                padding: "dense",
                pageSize: 20
              }}
              editable={{
                onRowAdd,
                onRowUpdate,
                onRowDelete
              }}
            />
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Table;
