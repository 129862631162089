import React, { useState, useEffect } from "react";
import { Button, Link, Paper } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { updateRepo, restartTask, stopTask, runTask } from "../../../api/systemctl";
import { Settings } from "../../../Settings";

const wait = deley => new Promise(resolve => setTimeout(() => resolve(), deley));


export default (props) => {

    const { socket } = props;

    useEffect(() => {
        window.aceLog = window.ace.edit(document.querySelector("#log-section"));
        window.aceLog.getSession().setMode("ace/mode/javascript");
        window.aceLog.getSession().setUseSoftTabs(true);
        window.aceLog.getSession().setUseWrapMode(true);
        window.aceLog.getSession().setOption("useWorker", false);
        window.aceLog.setOption("fontSize", "11px");
        window.aceLog.$blockScrolling = Infinity;

        socket.send('subscibeToLiveLog');

        socket.on("liveLog", log => {
            const session = window.aceLog.getSession();
            const length = (window.aceLog.getValue() || "").length;
            const text = log.join("|");

            let sep = "";

            if (length > 0 && !/\n$/.test(text)) sep = "\n";

            session.insert(
                {
                    row: session.getLength(),
                    column: 0
                },
                text + sep
            );

            window.aceLog.gotoLine(Infinity);
        });

        return () => {
            socket.off("liveLog");
            socket.send('unsubscibeToLiveLog');
            window.aceLog.setValue("");
        };
    }, []);


    return (
        <Paper>
            <div style={{
                marginTop: 5,
                flexGrow: 1,
                minHeight: "84vh"
            }} id="log-section"></div>
        </Paper>
    );
}