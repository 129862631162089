import { Storage } from "./storage";
import Emitter from "./emitter";
import { useEffect, useState } from "react";

class Store extends Emitter {
  constructor({ key, defaultShchema = {} }) {
    super();

    this.defaultShchema = defaultShchema;
    this.storageKey = key;
    this.temporary = key !== undefined ? false : true;
    this._ = {};
    this.__ = {};
  }

  async init() {
    if (this.temporary === false) {
      const _ = await Storage.getJSON(this.storageKey);

      this._ = _ || {};
    } else {
      this._ = {};
    }

    this.memorize("event-inited", true);
  }

  get(oKey, def = undefined, handler = void 0) {
    if (oKey === undefined) return this._;

    let [key] = oKey.split(Emitter.delimetr);

    const isFunction = typeof handler === "function";

    const rRes = get(this.__, key);

    isFunction && this.on(oKey, handler);

    if (rRes !== undefined)
      return isFunction ? handler({ next: rRes, def }) : rRes;

    const res = get(this._, key);

    if (res !== undefined)
      return isFunction ? handler({ next: res, def }) : res;

    const dRes = get(this.defaultShchema, key);
    if (dRes !== undefined)
      return isFunction ? handler({ next: dRes, def }) : dRes;

    if (def !== undefined)
      return isFunction ? handler({ next: def, def }) : def;

    return isFunction ? handler({ next: undefined, def }) : undefined;
  }

  remove(okey) {
    this.clear(okey);

    this.off(okey);
  }

  clear(key) {
    if (get(this._, key) !== undefined) {
      this.set(key, void 0);
      this.save();
    } else {
      this.memorize(key, void 0);
    }
  }

  set(key, value, temp = this.temporary) {
    [key] = key.split(Emitter.delimetr);

    const kArr = key.split(".");

    if (!temp) {
      const prev = get(this._, key);

      set(this._, key, value);

      const root = get(this._, kArr[0]);

      kArr.reduce((acc, elem) => {
        acc.push(elem);

        const key = acc.join(".");

        this.emit(key, {
          prev: acc.length === kArr.length ? prev : void 0,
          next: get(this._, key),
          key,
          root
        });

        return acc;
      }, []);

      this.save();
    } else {
      const prev = get(this.__, key);

      set(this.__, key, value);

      const root = get(this.__, kArr[0]);

      kArr.reduce((acc, elem) => {
        acc.push(elem);

        const key = acc.join(".");

        this.emit(key, {
          prev: acc.length === kArr.length ? prev : void 0,
          next: get(this.__, key),
          root,
          key
        });

        return acc;
      }, []);
    }

    return true;
  }

  has(key) {
    return !!this.get(key);
  }

  memorize(key, value) {
    return this.set(key, value, true);
  }

  memorizeAssign(key, object) {
    if (this.__[key]) {
      this.__[key] = Object.assign({}, this.__[key], object);

      this.emit(key, { prev: {}, next: this.__[key] });
    }
  }

  async save() {
    if (this.temporary === false) Storage.setJSON(this.storageKey, this._);
  }

  useStore(key, defValue){   
    const [value, setter] = useState(this.get(key,defValue));

    return [value, (value) => {
      this.memorize(key, value);
      setter(value);
    }]
  }
}

export { Store };

export const get = (obj, path, defaultValue) => {
  if (typeof path === "string") path = path.split(".");

  let node = obj;

  for (let i = 0; i < path.length; i++) {
    const key = path[i];

    if (!node) {
      node = undefined;

      break;
    }

    node = node[key];
  }

  return typeof node === "undefined" ? defaultValue : node;
};

export const set = (obj, path, defaultValue, setter) => {
  if (typeof path === "string") path = path.split(".");

  if (typeof setter === "undefined") setter = defaultValue;

  let value = get(obj, path);

  if (
    (typeof value === "undefined" ||
      (typeof value === "number" && isNaN(value))) &&
    typeof defaultValue !== "function"
  )
    value = defaultValue;

  let node = obj;

  for (var i = 0; i < path.length - 1; i++) {
    const key = path[i];
    const nextKey = +path[i + 1];

    if (!node[key]) node[key] = isNaN(nextKey) ? {} : [];

    node = node[key];
  }

  node[path[i]] = typeof setter === "function" ? setter(value) : setter;

  return obj;
};
