import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import pluralize from "pluralize";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Checkbox from "@material-ui/core/Checkbox";
import FormLabel from "@material-ui/core/FormLabel";
import { Settings } from "../../../Settings";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  papaer: {
    marginTop: 10
  },
  minTab: {
    fontSize: 12
  },
  root2: {
    width: "100%"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  }
}));

export default function Policy(props) {
  const classes = useStyles();
  const service = props.currentService.name;
  const endpoint = pluralize(service);

  const [policies, setPolicies] = useState([]);
  const [endpoints, setEndpoints] = useState([]);

  useEffect(() => {
    props.socket
      .rpc("consulServices", {
        path: `nginx/services/${endpoint}/?recurse`
      })
      .then(({ json }) => {
        const list = [];

        json.forEach(item => {
          const route = item.Key.replace(`nginx/services/${endpoint}/`, "");

          if (item.Value !== null) list.push({ route });
        });

        return Promise.resolve(list);
      })
      .then(endpoints => {
        console.log(endpoints);
        setEndpoints(endpoints);
      });
  }, [endpoint]);

  useEffect(() => {
    props.socket
      .fetch(`http://${Settings.get("global_host")}/search/search`, {
        service: "policy"
      })
      .then(({ json }) => {
        setPolicies(
          json.data.map(p => {
            // p.statements.forEach()
            p._ = void 0;
            return p;
          })
        );
      });
  }, [props.currentService]);

  const onCheckboxChange = useCallback(
    (value, statement, policy, endpoint) => {
      const index = policy.statements.indexOf(statement);

      if (index === -1) {
        policy.statements.push({
          action: endpoint.route,
          effect: value ? "allow" : "deny",
          service: service
        });
      } else {
        policy.statements[index].effect = value ? "allow" : "deny";
      }

      props.socket
        .fetch(`http://${Settings.get("global_host")}/policies`, policy, "PUT")
        .then(({ json }) => {
          setPolicies(
            policies.map(p => {
              if (p._id === json._id) return json;
              return p;
            })
          );
        });
    },
    [policies, endpoints, props.currentService]
  );

  return (
    <div className={classes.root}>
      {policies.map(p => {
        return (
          <ExpansionPanel key={p._id}>
            <ExpansionPanelSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>{p._id}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <PolicyItem
                onCheckboxChange={onCheckboxChange}
                policy={p}
                endpoints={endpoints}
                currentService={props.currentService}
                socket={props.socket}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        );
      })}
    </div>
  );
}

const PolicyItem = function(props) {
  const classes = useStyles();

  if (!props.policy) return;

  return (
    <div>
      <List>
        {(props.endpoints || [])
          .filter(r => r != "")
          .map(endpoint => {
            const item = props.policy.statements.find((st, index) => {
              return (
                st.action === endpoint.route &&
                st.service === props.currentService.name
              );
            });

            const checked = item ? item.effect === "allow" : false;

            return (
              <ListItem key={endpoint.route}>
                <Checkbox
                  onChange={() =>
                    props.onCheckboxChange(
                      !checked,
                      item,
                      props.policy,
                      endpoint
                    )
                  }
                  checked={checked}
                  value="checkedA"
                  inputProps={{ "aria-label": endpoint.route }}
                />

                <FormLabel component="legend">{endpoint.route}</FormLabel>
              </ListItem>
            );
          })}
      </List>
    </div>
  );
};
