import React from "react";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import { Settings } from "../../../Settings";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1
  },
  margin: {
    margin: theme.spacing(2),
    marginRight: theme.spacing(3)
  },
  list: {
    height: "84vh",
    "overflow-y": "scroll"
  }
}));

export default function Services(props) {
  const classes = useStyles();

  const services = props.services.filter(service => {
    if (props.currentMachine.value.services[service.name]) {
      return true;
    } else
      return false;
  });

  return (
    <List className={classes.list}>
      {services.map(service => {
        let totalMemoty = 0;
        const sm = Settings.get(`load.${service.name}`, false);


        let tasks = Object.keys(service.value.tasks);

        const taskCount = tasks.reduce((prev = 0, value) => {
          return prev + (props.currentMachine.value.services[service.name].includes(value) || props.currentMachine.value.services[service.name].includes('*') ? 1 : 0)
        },0);



        if (sm) {
          for (let task in sm) {
            for (let part in sm[task]) {
              totalMemoty += + sm[task][part].memory;
            }
          }
        }

        return <ListItem
          selected={
            props.currentService
              ? props.currentService.name === service.name
              : false
          }
          button
          key={service.name}
          onClick={() => props.onClick(service)}
        >
          <ListItemText
            primary={service.name}
            secondary={`${service.running_tasks_count } из  ${taskCount} | ${~~totalMemoty} Mb`}
          />
        </ListItem>
      })}
    </List>
  );
}
