import React from "react";
import { Tabs, Button } from "@material-ui/core";
// import CssBaseline from "@material-ui/core/CssBaseline";
// import Toolbar from "@material-ui/core/Toolbar";
// import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Link } from "@material-ui/core";
import {
  getProcessList
  // getWebSocket,
  // closeSocket,
  // setHost,
  // getProjectData
} from "./../../../api/systemctl";
import Services from "./../components/Services";
import Service from "./../components/Service";
// import Process from "./../components/Process";
// import ToolProjectBar from "./components/ToolBar";
// import { Link as NavLink } from "react-router-dom";
// import { Link as NavLink } from "@react-navigation/web";
import { Settings } from "../../../Settings";
import Tab from "@material-ui/core/Tab";

// const projects = require("./../../constants/projects.json");

// const useStyles = makeStyles(theme => ({
//   content: {
//     flexGrow: 1,
//     padding: theme.spacing(3)
//   }
// }));

import MachineActions from './../components/MachineActions';
import DashboardActions from "../components/DashboardActions";
import LiveLog from './../components/LiveLog';

function Project(props) {
  const projectKey = props.match.params.key;
  const [isLiveMode, setLiveMode] = React.useState(false);

  const {
    socket,
    project,
    currentMachine,
    currentService,
    onsetCurrentMachine,
    onSetCurrentService,
    services,
    onSetServices,
    machines
  } = props;
  // const classes = useStyles();

  const changeMachine = value => {
    Settings.clear('services');

    onsetCurrentMachine(value);

    getProcessList().then(res => {
      const uServices = services.map(service => {
        const systemctlService = res[service.name] || {};

        service.running_tasks_count = systemctlService.length || 0;

        for (let t in service.value.tasks) {
          service.value.tasks[t].systemctl = undefined;
        }

        (Array.isArray(systemctlService) ? systemctlService : []).forEach(
          task => {
            if (service.value.tasks[task.task] === undefined) {
              service.value.tasks[task.task] = {
                systemctl: task
              };
            } else service.value.tasks[task.task].systemctl = task;
          }
        );

        return service;
      });

      console.log('changeMachine',uServices)


      onSetServices(uServices);
    });
  };

  if (!project) return null;

  return (
    <div>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Tabs
            value={currentMachine}
            onChange={(e, v) => {
              changeMachine(v);
            }}
          >
            {machines.map(machine => {
              return (
                <Tab key={machine.name} value={machine} label={machine.name} />
              );
            })}
          </Tabs>
        </Grid>
        <MachineActions project={project} />
        <DashboardActions isLiveMode={isLiveMode} setLiveMode={setLiveMode} />
        <Grid item xs={2} style={{ maxHeight: "84vh"}}>
          <Services
            project={project}
            services={services}
            currentMachine={currentMachine}
            currentService={currentService}
            onClick={service => onSetCurrentService(service)}
          />
        </Grid>
        <Grid item xs={10}>
          {isLiveMode && (
            <LiveLog socket={socket} />
          )}
          {!isLiveMode && (
            <>
              {currentService !== null && (
                <Service
                  socket={socket}
                  currentService={currentService}
                  projectKey={projectKey}
                  currentMachine={currentMachine}
                  project={project}
                />
              )}
            </>
          )}

        </Grid>
      </Grid>
    </div>
  );
}

Project.path = "project";

export default Project;
