import React, { useState, useEffect } from "react";

// import Divider from "@material-ui/core/Divider";
import { Chip, Box, Paper, Grid, Button } from "@material-ui/core";
import { Typography } from "@material-ui/core";
// import ListItem from "@material-ui/core/ListItem";
// import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import { restartTask, stopTask, runTask } from "./../../../api/systemctl";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1
  },
  logSection: {
    flexGrow: 1,
    height: "80vh"
  },
  chip: {
    margin: theme.spacing(1)
  },
  paper: {
    marginTop: 10
  },
  button: {
    marginRight: 20
  }
}));

export default function LogContainer(props) {
  const classes = useStyles();
  const { process, socket, currentMachine, partitiion } = props;

  let index = 0;

  if (process)
  (Array.isArray(process.systemctl)  ? process.systemctl : []).forEach((item, i) => {
    if (item.partition === props.partition) {
      index = i;
    }
  })



  useEffect(() => {
    socket.on("debugUrl", url => {
      setDebugUrl(url);
    });

    socket.send("subscribe", {
      task: `reaper-${props.service}-${props.task}-${props.partition}`
    });

    return () => {
      socket.send("unsubscribe", {
        task: `reaper-${props.service}-${props.task}-${props.partition}`
      });
      socket.off("debugUrl");
    };
  }, [process, socket.host, props.partition]);

  const [debugUrl, setDebugUrl] = useState(null);

  if (!process) return null;

  const onRestartClick = () => {
    if (process.systemctl && process.systemctl[index]) {
      restartTask(process.systemctl[index].sysId);
    }
  };

  const onAddClick = () => {
    runTask(`${props.service}-${props.task}-${(Date.now() / 1000000000).toString().split('.')[1]}`);
  }

  const onStopClick = () => {
    if (process.systemctl && process.systemctl[index]) {
      // return alert('Пока не стоит');
      stopTask(process.systemctl[index].sysId);
    }
  };

  const onRunClick = () => {
    runTask(`${props.service}-${props.task}-${(Date.now() / 1000000000).toString().split('.')[1]}`);
  };

  const copyDebugUrl = () => {
    copyToClipboard(debugUrl);
  };

  return (
    <div style={{ padding: 20, paddingTop: 10, paddingBottom: 10 }}>
      <Grid container spacing={0}>
        <Grid item xs={10}>
          <Typography variant="h5" noWrap>
            {props.service}-{props.task}-{props.partition}
            {process.systemctl && process.systemctl[index] ? (
              process.systemctl[index].status.map(status => {
                return (
                  <Chip
                    key={status}
                    label={status}
                    size="small"
                    className={classes.chip}
                  />
                );
              })
            ) : (
                <Chip
                  color="secondary"
                  size="small"
                  label={"Не запущен"}
                  className={classes.chip}
                />
              )}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          {debugUrl && process.systemctl && process.systemctl[index] && (
            <Button
              onClick={copyDebugUrl}
              size="small"
              color="primary"
              className={classes.margin}
            >
              Отладка (Скопировать в буфер)
            </Button>
          )}
        </Grid>
        <Grid item xs={12}>
          {process.systemctl && process.systemctl[index] ? (
            <>
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={onAddClick}
                className={classes.button}
              >
                +
              </Button>
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={onRestartClick}
                className={classes.button}
              >
                Перезапустить
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={onStopClick}
                className={classes.button}
              >
                Остановить
              </Button>
            </>
          ) : (
              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={onRunClick}
                className={classes.button}
              >
                Запустить
              </Button>
            )}
        </Grid>
      </Grid>
    </div>
  );
}

const copyToClipboard = str => {
  const el = document.createElement("textarea");
  el.value = str;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};
