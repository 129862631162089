import React, { useEffect, useState, useRef } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Grid } from "@material-ui/core";

import {
  getProcessList,
  getWebSocket,
  closeSocket,
  setHost,
  getProjectData
} from "./../../api/systemctl";

import ProjectContent from "./Project/Project";
import Translate from "./Translate";
import ToolProjectBar from "./components/ToolBar";
import { Route, Switch as ReactSwitch } from "react-router-dom";
import { Settings } from "../../Settings";

const projects = require("./../../constants/projects.json");

function Project(props) {
  const projectKey = props.match.params.key;

  const [machines, setMachines] = Settings.useStore("machines", []);
  const [currentMachine, setCurrentMachine] = Settings.useStore(
    "current_machines",
    void 0
  );
  const [services, setServices] = Settings.useStore("services", []);
  const [currentService, setCurrentService] = useState(null);
  const [socket, setSocket] = Settings.useStore("socket", null);

  const project = projects.find(p => p.key === projectKey);

  const servicesRef = useRef();

  useEffect(() => {
    servicesRef.current = services;
  }, [services]);

  useEffect(() => {
    if (socket === null) return;

    setCurrentService(servicesRef.current[0]);
  }, [socket]);


  useEffect(() => {
    if (socket === null) return;

    socket.on("load", (message) => {
      const res = {};
      
      Settings.memorize('load', {});

      for (let k in message){
        const [service, task, part] = k.split('-');

        res[service] === undefined && (res[service] = {})

        res[service][task] === undefined &&  (res[service][task] = {})

        res[service][task][part] = message[k];
      }

      Settings.memorize('load', res)
    });

    return () => socket.on('load');
  }, [socket])

  setHost(project.host); //  Set main host
  Settings.memorize("global_host", project.global_host);

  useEffect(() => {
    if (!currentMachine) return;

    if (socket === null) {
      const _socket = getWebSocket(currentMachine.value.host);

      _socket.on("connect", () => {
        setSocket(_socket);
      });

      _socket.on("onopen", () => {
        setSocket(_socket);
      });

    } else {
      socket.changeHost(currentMachine.value.host);
    }

    return () => {
      Settings.clear("socket");
      return closeSocket();
    };
  }, [currentMachine]);

  useEffect(() => {
    if (!currentMachine || !servicesRef.current) return;

    const f = () =>
      getProcessList().then(res => {
        if (servicesRef.current.length === 0) return;

        // servicesRef.current.forEach(item => {
        //   for (let t in item.value.tasks){
        //     item.value.tasks[t].systemctl = [];
        //   }
        // })

        const uServices = servicesRef.current.map(service => {
          const systemctlService = res[service.name] || {};

          service.running_tasks_count = systemctlService.length || 0;

          for (let t in service.value.tasks) {
            service.value.tasks[t].systemctl = [];
          }

          (Array.isArray(systemctlService) ? systemctlService : []).forEach(
            task => {
              if (service.value.tasks[task.task] === undefined) {
                service.value.tasks[task.task] = {
                  systemctl: [task]
                };

              } else service.value.tasks[task.task].systemctl.push(task);
            }
          );


          return service;
        });

        setServices(uServices);
      });

    const timer = setInterval(() => {
      f();
    }, 3000);

    f();

    return () => {
      clearInterval(timer);
    };
  }, [currentMachine]);

  useEffect(() => {
    getProjectData().then(res => {
      const m = res.machines.map(m => {
        const [, , name] = m.Key.split("/");
        const value = JSON.parse(atob(m.Value));

        return {
          name,
          value
        };
      });

      setMachines(m);

      setCurrentMachine(m[0]);

      const s = res.services.map(m => {
        const [, , name] = m.Key.split("/");
        const value = JSON.parse(atob(m.Value));

        return {
          name,
          value,
          tasks_count: Object.keys(value.tasks).length,
          running_tasks_count: 0
        };
      });

      setServices(s);
    });

    return () => {
      Settings.clear("machines");
      Settings.clear("current_machines");
      Settings.clear("services");
      Settings.clear("socket");
    };
  }, []);

  if (!project) return null;

  return (
    <div>
      <CssBaseline />
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <ToolProjectBar project={project} />
        </Grid>
        <Grid item xs={12}>
          <ReactSwitch>
            <Route
              exact
              path="/project/:key"
              render={props => (
                <ProjectContent
                  {...props}
                  onSetServices={setServices}
                  socket={socket}
                  project={project}
                  currentMachine={currentMachine}
                  currentService={currentService}
                  onsetCurrentMachine={setCurrentMachine}
                  onSetCurrentService={setCurrentService}
                  services={services}
                  onSetServices={setServices}
                  machines={machines}
                />
              )}
            />
            <Route
              exact
              path="/project/:key/translate"
              render={props => (
                <Translate {...props} project={project} socket={socket} />
              )}
            />
          </ReactSwitch>
        </Grid>
      </Grid>
    </div>
  );
}

// Project.path = "project";

export default Project;
