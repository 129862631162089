import { Settings } from "../Settings";

let host;
const test = /^reaper@/;
let socket = undefined;

export const setHost = h => {
  host = h;
};

export const getHost = () => {
  return host;
};

const post = (payload = {}, path, useMainHost = false) => {
  const u = `sdbus/method`;
  let h = host;

  if (!useMainHost) h = Settings.get("current_machines.value.host");

  if (!host) return Promise.resolve([]);

  return fetch(`http://${h}:29424/${path ? path : u}`, {
    method: "POST",
    body: JSON.stringify(payload)
  });
};

export const updateRepo = (serviceName, projectPrefix) => {
  return post(
    {
      name: serviceName,
      project: projectPrefix
    },
    "update_service",
    false
  ).then(res => res.text());
};

export const getProjectData = object => {
  return post({}, "get_project_data", true).then(res => res.json());
};

export const restartTask = object => {
  return post({
    object: object,
    interface: "org.freedesktop.systemd1.Unit",
    method: "Restart",
    args: ["replace"]
  });
};

export const stopTask = object => {
  return post({
    object: object,
    interface: "org.freedesktop.systemd1.Unit",
    method: "Stop",
    args: ["replace"]
  });
};

export const runTask = (task = "address-listener-0") => {
  return post(
    {
      name: `systemctl start reaper@${task}`,
      options: {
        env :{
          DEBUG_LEVEL:-1
        }
      }
    },
    "cmd"
  );
};

export const getWebSocket = (host = "tiki.bet.io") => {
  if (!socket) socket = new Socket(host);
  return socket;
};

export const closeSocket = () => {
  if (socket) socket.close();

  socket = void 0;
};

export const startTask = object => {
  return post({
    object: object,
    interface: "org.freedesktop.systemd1.Unit",
    method: "Start",
    args: ["replace"]
  });
};

export const getProcessList = () => {
  return post({
    object: "/org/freedesktop/systemd1",
    interface: "org.freedesktop.systemd1.Manager",
    method: "ListUnits",
    args: []
  })
    .then(res => (Array.isArray(res) ? res : res.json()))
    .then(res => {
      const p = res.filter(i => test.test(i[0]));
      const groups = {};

      p.forEach(item => {
        const [, service, task, partition] = item[0].match(
          /reaper@(.*?)-(.*?)-(.*)\./i
        );

        if (groups[service] === undefined) {
          groups[service] = [];
        }

        groups[service].push({
          service,
          task,
          status: [item[2], item[3], item[4]],
          sysId: item[6],
          partition
        });
      });

      return groups;
    });
};

class Socket {
  constructor(host) {
    this._listaners = {};
    this.host = this.makeWSURL(host);
    this.needClose = false;
    this.init();

    this.on("sendResponse", data => {
      this.dispatch(data._rid, data);
    });
  }

  makeWSURL(host) {
    return `ws://${host}:18181`;
  }

  changeHost(host) {
    this.host = this.makeWSURL(host);
    this.close();
    this.init();
  }

  close() {
    this.needClose = true;
    this.ready = false;
    clearInterval(this.pingInterval);
    this.socket.close();
  }

  init() {
    this.ready = false;
    this.socket = new WebSocket(this.host);

    this.socket.onopen = event => {
      this.ready = true;
      this.dispatch("connect");
    };

    this.socket.onmessage = event => {
      const data = JSON.parse(event.data);

      this.dispatch(data.event, data.data);
    };

    this.socket.onclose = () => {
      if (this.needClose) return;

      this.ready = false;
      setTimeout(() => this.init(), 1000);
    };

    this.socket.onerror = () => {};

    this.pingInterval = setInterval(() => {
      this.send("ping");
    }, 2000);
  }

  send(cmd, data) {
    if (!this.ready) return;

    this.socket.send(JSON.stringify({ cmd, data }));
  }

  dispatch(name, data) {
    if (this._listaners[name] === undefined) return;

    this._listaners[name].forEach(function(cb) {
     cb && cb(data);
    });
  }

  off(name, cb) {
    if (this._listaners[name] === undefined) return;

    if (cb === undefined) {
      delete this._listaners[name];
    } else {
      const index = this._listaners[name].indexOf(cb);

      if (index !== -1) this._listaners[name].splice(index, 1);
    }
  }

  on(name, fn) {
    if (this._listaners[name] === undefined) this._listaners[name] = [];

    this._listaners[name].push(fn);
  }

  rpc(cmd, data = {}) {
    data._rid = ~~(Math.random() * 100000000) + "";

    return new Promise((resolve, reject) => {
      const f = data => {
        resolve(data);
        this.off(data._rid, f);
      };

      this.on(data._rid, f);

      this.send(cmd, data);
    });
  }

  fetch(url = "", body = {}, method = "POST") {
    const data = { url, method, body };

    data._rid = ~~(Math.random() * 100000000) + "";

    return new Promise((resolve, reject) => {
      const f = data => {
        resolve(data);
        this.off(data._rid, f);
      };

      this.on(data._rid, f);

      this.send("fetch", data);
    });
  }
}
